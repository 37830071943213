<template>
    <div class="wrapper_product"
         v-if="this.page_settings"
    >

        <loading v-if="!this.$store.getters.loading" />

        <headerBg data-aos-duration="2000" data-aos-delay="200" :white="true" data-aos="fade-up"
            :title="data.page_settings.products_title" :description="data.page_settings.product_description"
            :down="true" :bg="'https://api.interchim.bg/storage/' + data.page_settings.products_bg" />

        <div class="product">

            <carusel-wrapper data-aos-duration="2000" data-aos-delay="200" data-aos="fade-up" :slides-per-view="1"
                :slidesPerViewTables="1" :slidesPerViewMobile="1" :tabs="true" :tabsProduct="data.products"
                :title="data.page_settings.slider_title" :description="data.page_settings.slider_description">

                <carusel-item data-aos-duration="2000" data-aos-delay="200" data-aos="fade-up"
                    v-for="(product, i) in data.products" :key="product[i]">
                    <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                        :btn-see-link="'/' + locale + data.header_footer.menu[2].slug + '/' + product.slug"
                        :reverse="true" :img="product.media.image[0].default" :title="product.title"
                        :description="product.short_description" :btn-title="data.page_settings.banner_button_text" />

                </carusel-item>

            </carusel-wrapper>

            <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                :img="'https://api.interchim.bg/storage/' + data.page_settings.image"
                :subtitle="data.page_settings.subtitle" :title="data.page_settings.title"
                :description="data.page_settings.description" />


        </div>
    </div>
</template>

<script>
    import loading from '../components/loading'
    import {
        RepositoryFactory
    } from "../api/RepositoryFactory";
    const GetRepository = RepositoryFactory.get('data');


    import headerBg from "../components/app/headerBg";
    import sectionRigtLeft from "../components/home/sectionRigtLeft";
    import CaruselWrapper from "../components/carusel/caruselWrapper";
    import CaruselItem from "../components/carusel/caruselItem";
    export default {
        name: "Products",

        components: {
            CaruselItem,
            CaruselWrapper,
            headerBg,
            sectionRigtLeft,
            loading
        },
        async mounted() {
            await this.fetch()
        },
        data() {
            return {
                isLoading: false,
                data: {},
                title: null,
                page_settings: '',
                meta: [],
                og: [],
                locale: window.localStorage.getItem('locale')
            }
        },

        methods: {
            fetch() {
                this.$store.dispatch('SET_LOADING_AXIOS', 2000)
                return new Promise((resolve, reject) => {
                    GetRepository.getProducts()
                        .then(resp => {
                            this.isLoading = false;
                            this.data = resp.data.data;
                            this.page_settings = resp.data.data.page_settings;
                            this.title = this.data.page.meta_title;
                            this.og = this.data.meta.og.meta;
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
            },
        },

        watch: {
            '$route.params.slug': {
                immediate: true,
                handler() {
                    this.fetch()
                },
            },
        },

        metaInfo() {
            return {
                title: this.title,
                meta: this.og
            }
        },
    }
</script>

<style scoped lang="scss">
    @import '../assets/scss/index';

    .product {
        @include sectionGlobal
    }
</style>